import { useMutation } from "react-query";
import { extendedPostJSON } from "../api/http";
import { endpoints } from "../api/endpoints";
import { useState } from "react";

const postPaymentSend = (data: any) =>
  extendedPostJSON(endpoints.user.payment.send, data);

export const usePaymentSend = () => {
  const [errors, setErrors] = useState<string[]>([]);

  const { mutate, isLoading, isSuccess, isError, reset } = useMutation(
    postPaymentSend,
    {
      onSuccess: (data) => {
        const { redirect_url } = data.data;
        // window.open(redirect_url);
        setTimeout(() => {
          window.open(redirect_url);
        })
      },
      onError: (error: any) => {
        setErrors(Object.values(error?.data.errors || {}));
      },
      onSettled: () => { },
    }
  );

  return {
    isLoading,
    isSuccess,
    isError,
    errors,
    paymentSend: mutate,
    reset,
  };
};
