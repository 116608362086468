import { Flex, ListItem, UnorderedList, Text, List, Button, Box, Table, Tr, Th, Td, Select } from "@chakra-ui/react";
import { FC, Fragment, useEffect, useState } from "react";
import { Card, CustomedTableBody, CustomedTableHead, MobileTableBody } from "../..";
import { useInternetList } from "../../../../hooks/useInternetList";
import { useParentalControlOptions } from "../../../../hooks/useParentalControlOptions";
import { formatClock, restructureDate, timestampToDateString } from "../../../utils";
import { time } from "console";
import { CustomedButton } from "../..";
import { STATUS, useParentalControlData } from "../../../../hooks/useParentalControlData";
import { useChangeParentalControlData } from "../../../../hooks/useChangeParentalControlData";
import { useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";


const generateHourlyTimes = () => {
    const startHours = [];
    const endHours = [];

    for (let hour = 0; hour < 24; hour++) {
        const formattedHour = hour.toString().padStart(2, '0');
        startHours.push(`${formattedHour}:00:00`);
        endHours.push(`${formattedHour}:59:59`);
    }

    return { startHours, endHours };
};

const { startHours, endHours } = generateHourlyTimes();

export interface IRParentalControlTable {
    id: string;
}

export interface IParentalControlData {
    status: string;
    type: string;
    start: string;
    end: string;
}

interface IParentalControlDataToSend {
    access: string;
    workhour_type: string;
    workhour_start: string;
    workhour_end: string;
}



export const ParentalControlTable: FC<IRParentalControlTable> = ({ id }) => {
    // let {id} = useParams()
    const [testobject, setTestObject] = useState<IParentalControlData>({
        status: '',
        type: '',
        start: '',
        end: ''
    })
    const parentalControlOptions = useParentalControlOptions().data;
    const { data, refetch, isLoading } = useParentalControlData({ id });

    const [status, setStatus] = useState('')
    const [type, setType] = useState('')
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')


    const { /* isLoading, */ isSuccess, isError, error, saveParentalSettings } =
        useChangeParentalControlData(refetch);

    //logowanie dla testow
    // console.log('id internet: ' + id)
    // console.log('parental options: ' + JSON.stringify(parentalControlOptions))


    const {
        handleSubmit,
        register,
        getValues,
        control
    } = useForm<IParentalControlDataToSend>();

    const getStatus = () => {
        let destructuredStatus;

        if (status) destructuredStatus = status === STATUS.ON ? '1' : '0';
        else destructuredStatus = data.status === STATUS.ON ? '1' : '0'

        return destructuredStatus;
    }

    const getType = () => {
        let destructuredType;

        if (type) destructuredType = type.includes(STATUS.ON) ? '1' : '2';
        else destructuredType = data.status === STATUS.ON ? '1' : '2'

        return destructuredType;
    }

    const collectData = () => {
        const dataToSend = {
            access: getStatus(),
            workhour_type: getType(),
            workhour_start: start ? start : data.start,
            workhour_end: end ? end : data.end,
        }
        // console.log('data to send: ' + JSON.stringify(dataToSend))
        return dataToSend
    }


    const getValueByKey = (prop: string, o: object) => {

        for (let i in Object.keys(o)) {
            let c = prop === Object.keys(o)[i]
            if (c) return Object.values(o)[i]

        }

    }

    return (

        <Table variant="simple" >
            <CustomedTableHead>
                <Tr>
                    <Th>Status adresu IP</Th>
                    <Th>Internet</Th>
                    <Th>Od</Th>
                    <Th>Do</Th>
                </Tr>
            </CustomedTableHead>

            {(!isLoading) ? <CustomedTableBody>

                <Tr key={id}>
                    <Td>
                        <Select
                            value={status ? status : data.status}
                            onChange={(e) => {
                                const { target } = e;
                                if (target.type === 'select-one') {
                                    const selectValue = target.selectedOptions[0].value;
                                    setStatus(selectValue)
                                }
                            }}
                        >
                            {Object.values(STATUS).map((option: any, index) => (
                                <option key={index}>{option}</option>
                            ))
                            }
                        </Select>
                    </Td>
                    <Td>
                        <Select
                            value={type ? type : getValueByKey(data.type, parentalControlOptions)}
                            onChange={(e) => {
                                const { target } = e;
                                if (target.type === 'select-one') {
                                    const selectValue = target.selectedOptions[0].value;
                                    setType(selectValue)
                                }
                            }}
                        >
                            {Object.values(parentalControlOptions).map((option: any, index: any) => (
                                <option value={option.toString()} key={index}>{option.toString()}</option>
                            ))
                            }
                        </Select>
                    </Td>
                    <Td>
                        <Select
                            value={start ? start : data.start}
                            onChange={(e) => {
                                const { target } = e;
                                if (target.type === 'select-one') {
                                    const selectValue = target.selectedOptions[0].value;
                                    setStart(selectValue)
                                }
                            }}
                        >
                            {startHours.map((hour, index) => (
                                <option value={hour.toString()} key={index}>{hour}</option>
                            ))
                            }
                        </Select>
                    </Td>
                    <Td>
                        <Select
                            value={end ? end : data.end}
                            onChange={(e) => {
                                const { target } = e;
                                if (target.type === 'select-one') {
                                    const selectValue = target.selectedOptions[0].value;
                                    setEnd(selectValue)
                                }
                            }}
                        >
                            {endHours.map((hour, index) => (
                                <option value={hour.toString()} key={index}>{hour}</option>
                            ))
                            }
                        </Select>
                    </Td>
                    <Td>
                        <Button
                            onClick={() => { saveParentalSettings({ addressId: id, parentalControlData: collectData() }) }}
                        >Zapisz</Button>
                    </Td>
                </Tr>

            </CustomedTableBody> : null}


            <MobileTableBody>
                <Tr >
                    <Td>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontSize="14px" mr={'10px'} color="darkerPrimary" fontWeight="600">
                                Status adresu IP
                            </Text>
                            <Select
                                value={status ? status : data.status}
                                onChange={(e) => {
                                    const { target } = e;
                                    if (target.type === 'select-one') {
                                        const selectValue = target.selectedOptions[0].value;
                                        setStatus(selectValue);
                                    }
                                }}
                            >
                                {Object.values(STATUS).map((option, index) => (
                                    <option key={index}>{option}</option>
                                ))}
                            </Select>
                        </Flex>
                    </Td>
                </Tr>
                <Tr>
                    <Td>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontSize="14px" mr={'25px'} color="darkerPrimary" fontWeight="600">
                                Internet
                            </Text>
                            <Select
                                value={type ? type : getValueByKey(data.type, parentalControlOptions)}
                                onChange={(e) => {
                                    const { target } = e;
                                    if (target.type === 'select-one') {
                                        const selectValue = target.selectedOptions[0].value;
                                        setType(selectValue);
                                    }
                                }}
                            >
                                {Object.values(parentalControlOptions).map((option: any, index) => (
                                    <option value={option.toString()} key={index}>
                                        {option.toString()}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Td>
                </Tr>
                <Tr>
                    <Td>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontSize="14px" mr={'55px'} color="darkerPrimary" fontWeight="600">
                                Od
                            </Text>
                            <Select
                                value={start ? start : data.start}
                                onChange={(e) => {
                                    const { target } = e;
                                    if (target.type === 'select-one') {
                                        const selectValue = target.selectedOptions[0].value;
                                        setStart(selectValue);
                                    }
                                }}
                            >
                                {startHours.map((hour, index) => (
                                    <option value={hour.toString()} key={index}>
                                        {hour}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Td>
                </Tr>
                <Tr>
                    <Td>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontSize="14px" mr={'55px'} color="darkerPrimary" fontWeight="600">
                                Do
                            </Text>
                            <Select
                                value={end ? end : data.end}
                                onChange={(e) => {
                                    const { target } = e;
                                    if (target.type === 'select-one') {
                                        const selectValue = target.selectedOptions[0].value;
                                        setEnd(selectValue)
                                    }
                                }}
                            >
                                {endHours.map((hour, index) => (
                                    <option value={hour.toString()} key={index}>{hour}</option>
                                ))
                                }
                            </Select>
                        </Flex>
                    </Td>
                </Tr>
                <Tr>
                    <Td>
                        <Flex justifyContent="center" alignItems="center">
                            <Button
                                onClick={() => { saveParentalSettings({ addressId: id, parentalControlData: collectData() }) }}
                            >Zapisz</Button>
                        </Flex>
                    </Td>
                </Tr>

            </MobileTableBody>

        </Table >
    );

};
